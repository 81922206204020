<template>
  <v-container fluid>
    <v-card v-if="
      $store.state.companyDetails &&
      typeof $store.state.companyDetails.kycDetails != 'undefined' &&
      !$store.state.companyDetails.kycDetails.kycVerified
    " class="pa-4 my-2 d-block d-sm-flex justify-space-between elevation-2" two-line
      style="background-color: #a8e8a882;">
      <div class="d-flex align-center customBorderRadius">
        <v-btn color="primarygrad" fab small>
          <v-icon color="white">mdi-account-alert</v-icon>
        </v-btn>
        <p class="mb-0 mx-3">
          Your KYC verification is pending. Please complete your KYC
          verification.
        </p>
      </div>


      <div class="d-flex flex-row-reverse">
        <v-btn color="primarygrad" style="color: white;" @click="$router.push({ path: '/organization' })">
          KYC Verification
        </v-btn>
      </div>
    </v-card>

    <v-card class="pa-4 my-2 d-block d-sm-flex justify-space-between elevation-2" two-line
      style="background-color: #ec9e98;">
      <div class="d-flex align-center customBorderRadius">
        <v-btn color="#913a33" fab small>
          <v-icon color="#ec9e98">mdi-account-alert</v-icon>
        </v-btn>
        <p class="mb-0 mx-3">
          Our voice broadcast service is currently undergoing maintenance to implement the new TRAI guidelines. We will
          have it up and running again as soon as the process is complete.
        </p>
      </div>


      <!-- <div class="d-flex flex-row-reverse">
        <v-btn
          color="primarygrad"
          style="color: white;"
          @click="$router.push({ path: '/organization' })"
        >
          KYC Verification
        </v-btn>
      </div> -->
    </v-card>

    <div style="padding-bottom: 4%;">
      <v-card class="d-flex align-center justify-space-between pa-3 pa-sm-2" color="primarygrad">
        <div class="d-flex align-center">
          <v-img src="../../assets/credit.svg" class="credit-img ma-0 mr-2"></v-img>
          <p class="ma-0 custom-color">
            {{
              $store.getters.companyDetails.vbiCredits &&
              $store.getters.companyDetails.vbiCredits
            }}
          </p>
        </div>
        <v-btn rounded small outlined color="white" class="white--text" @click="buyCreditsDialogOpen()">
          <v-icon color="white">credit_card</v-icon>
          Buy Credits
        </v-btn>
      </v-card>
    </div>

    <v-container v-if="campaigns.length <= 0" fluid class="whiteBackGround">
      <first-page :img-src="imgSrc" :dialog-name.sync="noDialog" :points="points" :headline="headline"
        :btn-url="btnUrl"></first-page>
    </v-container>

    <v-container v-else fluid>
      <v-row>
        <v-col class="d-flex justify-start" style="font-size: 20px;">
          Your active campaign
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn color="#00CF46" class="white--text mr-3 text-transform" @click="campaignRedirct">
            Create campaign
            <v-icon small class="mr-2">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">Campaign</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">List</th>
                  <th class="text-left">Answered</th>
                  <th class="text-left">Not Answered</th>
                  <th class="text-left">Pending</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in campaigns" :key="item.id" :class="{ 'green-row': index % 2 === 1 }">
                  <td style="cursor: pointer;text-decoration: underline;" @click="goToCampaignPage(item)">
                    <v-img :src="item.icon" max-width="50" class="mr-2"></v-img>
                    {{ item.title }}
                  </td>
                  <td>{{ $moment(item.createdOn).format("lll") }}</td>
                  <td>
                    {{ item.listName }}
                  </td>
                  <td>
                    {{ item.status.answered }}
                  </td>
                  <td>
                    {{ item.status.notAnswered }}
                  </td>
                  <td>
                    {{ item.status.pending }}
                  </td>
                  <td v-if="item.audio">
                    {{ computedFileStatus(item) }}
                  </td>
                  <td>
                    <v-icon class="mr-2" color="green" style="font-size: 20px;" @click="goToCampaignPage(item)">
                      mdi-information
                    </v-icon>

                    <v-icon v-if="!item.callPlaced" class="mr-2" color="green" style="font-size: 20px;"
                      @click="startVoiceBroadcast(item)">
                      mdi-play
                    </v-icon>

                    <!-- <v-icon
                      small
                      class="mr-2"
                      color="green"
                      style="font-size: 20px;"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      color="red"
                      style="font-size: 20px;"
                      @click="removeForm(item)"
                    >
                      mdi-delete
                    </v-icon> -->
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="creditDialog" width="500" class="customRadiusDialog">
      <v-card>
        <v-card-title class="primarygrad" style="color: white">
          Buy Voice Broadcast Credits
          <v-spacer></v-spacer>
          <!-- <v-btn icon @click="surveyDialog = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn> -->
        </v-card-title>
        <v-card-text class="mt-3">
          <div align="center">
            <h4>Current Balance</h4>
            <h1 style="padding-bottom: 1.5%;">
              {{ $store.state.companyDetails.vbiCredits }} Credits
            </h1>
            <p>
              GoDial CRM charges 1 credit for every 1 voice broadcast
            </p>
          </div>

          <v-radio-group v-model="selectedCreditPackage">
            <v-radio label="Buy 1000 credits Rs.600 + GST" value="1000"></v-radio>
            <v-radio label="Buy 5000 credits Rs.2500 + GST" value="5000"></v-radio>
            <v-radio label="Buy 10000 credits Rs.4000 + GST" value="10000"></v-radio>
            <v-radio label="Buy 50000 credits Rs.12500 + GST" value="50000"></v-radio>
          </v-radio-group>
          <!-- <v-btn color="primary" @click="buyCredits">Buy</v-btn> -->
        </v-card-text>
        <v-card-actions class="justify-start" style="padding-bottom: 8%;">
          <v-btn color="#00CF46" class="white--text mr-3 text-transform" @click="buyCreditsZoho()">
            Buy
          </v-btn>
          <v-btn outlined color="#00CF46" class="text-transform" @click="creditDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import firstPage from "@/components/firstPage.vue";

export default {
  components: {
    firstPage,
  },
  props: ["item"],
  data() {
    return {
      selectedCreditPackage: 1000,
      creditDialog: false,
      noDialog: false,
      btnUrl: "/create-campaign",
      lists: [],
      snackbar: false,
      snackbarMessage: "",
      timeout: 3000,
      customName: "",
      fullURL: "https://enterprise.godial.cc/gopages/",
      firstTimeCampaign: false,
      form: {
        color: "", // Initially no color selected
      },
      website: "",
      pageName: "",
      colors: [
        "red",
        "pink",
        "purple",
        "indigo",
        "blue",
        "green",
        "yellow",
        "amber",
        "brown",
        "black",
      ],
      surveyDialog: false,
      script: "",
      campaigns: [], // collection of forms will be stored here
      loader: true, // to wait till the data is ready
      headline:
        "Send voice broadcast message to your customer with a click of a button. Simple choose your list, upload a voice file or generate a text to audio and click send.",
      points: [
        "Send audio broadcast to unlimited contacts",
        "Capture Press 1 (DTMF) response",
        "Low pricing at 25p per call",
      ],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "teams", "selectedTeam"]),
    imgSrc() {
      return require("../../assets/voice-broadcast-green.png");
    },
  },
  async created() {
    await this.refresh();
  },
  methods: {
    checkTime() {
      const now = moment().tz("Asia/Kolkata");
      const hour = now.hour();
      const minute = now.minute();

      // Check if the time is between 6 PM and 9 AM
      if (
        (hour >= 18 && hour <= 23) ||
        (hour >= 0 && hour < 9) ||
        (hour === 9 && minute === 0)
      ) {
        console.log("The current time in India is between 6 PM and 9 AM.");
        return true;
      } else {
        console.log("The current time in India is not between 6 PM and 9 AM.");
        return false;
      }
    },
    async startVoiceBroadcast(item) {
      var self = this;
      console.log("item", item);

      const isSixPm = self.checkTime();

      console.log("isSixPm", isSixPm);

      if (isSixPm) {
        return this.$swal({
          type: "error",
          text:
            "You can't place broadcast calls within 6pm to 9am due to telecom rules",
        });
      }

      if (typeof this.$store.state.companyDetails.kycDetails == "undefined") {
        return this.$swal({
          type: "error",
          text: "Please complete your KYC from settings.",
        });
      }

      const checkAudioApproval = await this.$http.post(
        `${this.ENDPOINT}/voiceBroadcasts/getAudioFile`,
        {
          campId: item.id,
        }
      );

      if (checkAudioApproval.body.approved) {
        try {
          var response = await this.$http.post(
            `${this.ENDPOINT}/voiceBroadcasts/startVoiceBroadcast`,
            {
              campId: item.id,
            }
          );
          console.log("place call resp: ", response.body);
          return this.$swal({
            type: "success",
            text:
              "Voice Broadcast placed successfully.It may takes upto 30 min to place call.Please check the report for update.",
          }).then((willGo) => {
            if (willGo.value) {
              this.refresh();
            }
          });
        } catch (e) {
          this.$swal({ type: "error", text: e.body.error.message });
        }
      } else {
        return this.$swal({
          type: "error",
          text: "Audio file is not approved. Please try after some time.",
        });
      }
    },
    computedFileStatus(item) {
      const { audio, callPlaced, status } = item;
      let fileStatus;

      if (typeof audio.approved == "undefined") {
        fileStatus = "Unknown";
      }

      if (!audio.approved) {
        fileStatus = "Pending Approval";
      } else if (audio.approved) {
        if (callPlaced && status.pending !== 0) {
          fileStatus = "In Progress";
        } else if (callPlaced && status.pending === 0) {
          fileStatus = "Completed";
        }
        fileStatus = "Approved";
      }
      console.log("fileStatus", fileStatus);
      return fileStatus.toUpperCase();
    },
    async refresh() {
      await this.fetchLists();
      await this.getVoiceBroadCast();

      this.$store.dispatch("getCompanyDetails", {
        companyId: this.$store.state.companyDetails.id,
      });
    },
    async buyCreditsZoho() {
      var self = this;

      if (!this.$store.state.companyDetails.paymentProfile.activated) {
        return this.$swal({
          title: "Error!",
          text:
            "Please check and update your payment profile, before generating invoice",
          type: "error",
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Go to Subscribe",
        })
          .then((willGo) => {
            if (willGo.value) {
              self.$router.push({ path: "/payments" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      try {
        let response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/billings/create-payment-invoice-voice-broadcast`,
          {
            creditPackage: Number(self.selectedCreditPackage),
          }
        );

        if (
          response.data &&
          response.data.success &&
          response.data.invoiceLink
        ) {
          // Redirect to Zoho payment URL provided by your server
          window.location.href = response.data.invoiceLink;
        } else {
          return self.$swal(
            "Error",
            "Failed to initiate payment process. Please try again.",
            "error"
          );
        }
      } catch (err) {
        console.log("err", err);

        return this.$swal({
          title: "Error!",
          text: err.body.error.message,
          type: "error",
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Go to Subscribe",
        })
          .then((willGo) => {
            if (willGo.value) {
              self.$router.push({ path: "/payments" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    buyCreditsDialogOpen() {
      this.creditDialog = true;
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];
        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        console.log("this.lists", JSON.stringify(this.lists));
        // if (this.lists.length < 1) {
        //   return;
        // }
        // this.selectedList = this.lists[0].id;
      } catch (e) {
        console.log("error in list fetch", e);
      }
    },
    campaignRedirct() {
      this.$router.push("/create-campaign");
    },
    async getVoiceBroadCast() {
      // getting all the forms in this account at the time of creation of the page
      await this.$http
        .get(
          `${this.$store.state.ENDPOINT}/voiceBroadcasts?filter[where][companyId]=${this.$store.state.companyDetails.id}&filter[include]=audio`
        )
        .then((res) => {
          this.campaigns = res.data.filter((obj) =>
            obj.hasOwnProperty("audio")
          );

          this.campaigns = this.campaigns.map((campaign) => {
            const list = _.find(this.lists, { id: campaign.listId });
            if (list) {
              campaign.listName = list.name;
            }
            return campaign;
          });

          console.log("this.campaigns", this.campaigns);

          this.loader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToCampaignPage(item) {
      console.log("items", item);

      this.$router.push({
        name: "Voice Broadcast Campaign", // Use route name for clarity
        params: { id: item.id || null }, // Pass the item as a parameter
      });
    },
    async createCampaign() {
      // this.$router.replace("/newgopages");

      if (this.customName == "") {
        return this.$swal({
          type: "error",
          text: "Page name is required",
        });
      }

      if (this.form.color == "") {
        return this.$swal({
          type: "error",
          text: "Theme color is required",
        });
      }

      if (this.customName != "") {
        var resp = await this.$http.get(
          `${this.ENDPOINT}/integrateForms?filter[where][companyId]=${this.$store.getters.companyDetails.id}&filter[where][name]=${this.customName}`
        );

        if (resp.body.length > 0) {
          return this.$swal({
            type: "error",
            text: "Page name is already taken.Please use another page name.",
          });
        }
      }

      var dataForForm = {
        name: this.customName,
        themeColor: this.form.color,
      };

      console.log("dataForForm", dataForForm);

      this.$router.push({
        name: "New Gopages", // Use route name for clarity
        params: { itemNameandColor: dataForForm }, // Pass the item as a parameter
      });
    },
    selectColor(color) {
      this.form.color = color;
      // this.generateHtml(); // Assuming this method exists for other operations
    },
    // removing the form
    async removeForm(form) {
      console.log(form);

      // await as the next step depends to the user's choice
      await this.$swal({
        type: "warning",
        text: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonColor: "#31a848",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((yes) => {
        // user confirmation

        this.$http
          .delete(`${this.$store.state.ENDPOINT}/integrateForms/${form.id}`)
          .then((res) => {
            this.$swal({
              type: "success",
              text: "Form is successfully deleted",
            });

            this.getForms();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    isMobile() {
      if (window.innerWidth <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.cs-box {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.image-container img {
  width: auto;
  /* Change this to auto to maintain aspect ratio */
  max-width: 100%;
  /* Prevents the image from exceeding the container width */
  height: auto;
}

.text-transform {
  text-transform: capitalize;
}

.whiteBackGround {
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-margin {
  margin: 0;
  /* Override any default margin */
}

@media (max-width: 760px) {
  .image-container {
    margin-bottom: 20px;
    /* Adds spacing between the image and text content on mobile */
  }
}

.customRadiusDialog {
  border-radius: 0px !important;
}

.v-application .color-avatar {
  cursor: pointer;
  border: 4px solid transparent;
  /* Maintains layout consistency */
  border-radius: 50%;
  /* Ensures the avatar is round */
  transition: border-color 0.3s;
}

.v-application .active-border {
  border-color: rgb(188 188 188) !important;
  /* Overrides Vuetify theme color */
}

.v-card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.v-simple-table td {
  vertical-align: middle;
}

.v-img {
  max-width: 50px;
  height: auto;
  display: inline-block;
}

.green-row {
  background-color: #cffede;
  /* Light green color */
}

.v-text-field__details {
  display: none;
}

.credit-img {
  max-width: 1.5rem;
  height: auto;
}

.custom-color {
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
}
</style>
